import React, { useEffect, useState, useRef } from "react";
import "./home.css";
import { FullPage, Slide } from "react-full-page";
import Header from "../../components/Header";
import Modal from "../../components/Modal";
import MainSection from "../../components/home/MainSection";
import SecondSection from "../../components/home/SecondSection";
import ThirdSction from "../../components/home/ThirdSction";
import FourthSection from "../../components/home/FourthSection";
import FifthSectionMinePet from "../../components/home/FifthSectionMinePet";
import SixthSection from "../../components/home/SixthSection";
import SeventhSection from "../../components/home/SeventhSection";
import Footer from "../../components/Footer";
import { useCustomMediaQuery, PC, Mobile } from "../../hooks/useMedia";
import useLanguage from "../../hooks/useLanguage";
import { Trans } from "react-i18next";
import { IconScroll } from "../../constants/icons";
import ModalText from '../../components/ModalText';

const HomePage = () => {
    const { t, langState } = useLanguage();
    const fullPageRef = useRef();

    const searchParams = new URLSearchParams(window.location.search);
    const referral = searchParams.get("referral") || "";
    const appLink = "https://plenty.onelink.me/yTQT/faeajz4x";
    const deferredDeepLink = `${appLink}?plentyReferral=${referral}&deep_link_value=${referral}`;

    const FULL_PAGES = [
        {
            id: "section1",
            color: "#FFFFFF",
            className: "section-main",
            component: (t) => <MainSection t={t} Trans={Trans} langState={langState} />,
        },
        {
            id: "section2",
            color: "#F8F9FF",
            className: "section-two",
            component: (t) => <SecondSection t={t} Trans={Trans} langState={langState} />,
        },
        {
            id: "section3",
            color: "#546CFF",
            className: "section-three",
            component: (t) => <ThirdSction t={t} Trans={Trans} langState={langState} />,
        },
        {
            id: "section4",
            color: "#FFFFFF",
            className: "section-four",
            component: (t) => <FourthSection t={t} Trans={Trans} langState={langState} />,
        },
        {
            id: "section5",
            color: "#EFF1FF",
            className: "section-five",
            component: (t) => <FifthSectionMinePet t={t} Trans={Trans} langState={langState} />,
        },
        {
            id: "section6",
            color: "#FFFFFF",
            className: "section-six",
            component: (t) => <SixthSection t={t} Trans={Trans} langState={langState} />,
        },
        {
            id: "section7",
            color: "#F8F9FFE0",
            className: "section-seven",
            component: (t) => <SeventhSection t={t} Trans={Trans} langState={langState} appLink={deferredDeepLink} />,
        },
        {
            id: "footer",
            className: "footer",
            component: (t) => <Footer t={t} Trans={Trans} langState={langState} />,
            // color: "#FFFFFF",
        },
    ];

    const [isDownloadPage, setIsDownloadPage] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [viewHeight, setViewHeight] = useState(window.innerHeight);
    const [isOpen, setIsOpen] = useState(false);
    const [isLanguageOpen, setIsLanguageOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [alertModalOpen, setAlertModalOpen] = useState(true);

    const setOpen = (flag) => {
        setIsOpen(flag);
    };

    const setLanguageOpen = (flag) => {
        setIsLanguageOpen(flag);
    };

    useEffect(() => {
        const handleResize = () => {
            setViewHeight(window.innerHeight);
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const onChangeSlide = (idx) => {
        if (fullPageRef.current) {
            fullPageRef.current.scrollToSlide(idx);
        }
    };

    useEffect(() => {
        if (referral) {
            window.location.href = window.location.href + "#section7";
        }
    }, [referral]);

    const onAfterChangeSlide = (e) => {
        const { to } = e;

        if (to === 6) {
            return setIsDownloadPage(true);
        }
        setIsDownloadPage(false);
    };

    const showScrollHandler = (e) => {
        if (window.scrollY > 0) {
            setIsShow(true);
        } else {
            setIsShow(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", showScrollHandler);
        return () => {
            window.removeEventListener("scroll", showScrollHandler);
        };
    }, []);

    return (
        <div className="home-page">
            <Header
                isLanguageOpen={isLanguageOpen}
                setIsLanguageOpen={setLanguageOpen}
                isOpen={isOpen}
                setIsOpen={setOpen}
                t={t}
                onChangeSlide={onChangeSlide}
                isDownloadPage={isDownloadPage}
                appLink={deferredDeepLink}
            />
            {/* {isModalOpen && <Modal onClose={() => setIsModalOpen(false)} />} */}
            {alertModalOpen && <ModalText onClose={() =>{ setAlertModalOpen(false)}} />}

            <PC>
                <div
                    className="container"
                    onClick={() => {
                        setIsOpen(false);
                        setIsLanguageOpen(false);
                    }}
                >
                    {!(isModalOpen || alertModalOpen) ? (
                        <FullPage ref={fullPageRef} afterChange={onAfterChangeSlide}>
                            {FULL_PAGES.map((item, idx) => (
                                <Slide style={{ backgroundColor: item.color }} id={item.id} className={item.className} key={`section-list-item-${idx}`}>
                                    {item.component(t)}
                                </Slide>
                            ))}
                        </FullPage>
                    ) : (
                        <div style={{ backgroundColor: "#fff", height: `${viewHeight}px`, maxHeight: `${viewHeight}px` }} id={"section1"} className={"section-main"} key={`section-list-item-0`}>
                            <MainSection t={t} Trans={Trans} langState={langState} />
                        </div>
                    )}
                </div>
            </PC>
            <Mobile>
                {/* <div className={`${isOpen ? "container-shadow" : ""}`} /> */}
                <div
                    className="container"
                    onClick={() => {
                        setIsOpen(false);
                        setIsLanguageOpen(false);
                    }}
                >
                    {FULL_PAGES.map((item, idx) => (
                        <div
                            style={{
                                backgroundColor: item.color,
                                // height: `${viewHeight}px`,
                                // maxHeight: `${viewHeight}px`,
                            }}
                            className={item.className}
                            key={`section-list-item-${idx}`}
                        >
                            {item.component(t)}
                        </div>
                    ))}
                    {isShow && (
                        <IconScroll
                            className="icon-scroll"
                            onClick={() => {
                                window.scrollTo(0, 0);
                            }}
                        />
                    )}
                </div>
            </Mobile>
        </div>
    );
};

export default HomePage;
